window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
    '--xs': '(max-width: 575.98px)',
    '--sm': '(max-width: 767.98px)',
    '--md': '(max-width: 991.98px)',
    '--lg': '(max-width: 1199.98px)',
};
$(() => {
    document.addEventListener('lazyloaded', function(e) {
        if (e.target.parentNode.classList.contains('loading')) {
            e.target.parentNode.classList.add('loaded');
        } else {
            e.target.parentNode.parentNode.classList.add('loaded');
        }

    });
})

