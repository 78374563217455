$(() => {
    if (document.getElementsByClassName('grid').length) {
        setTimeout(function() {
            const msnry = new Masonry( '.grid', {
                percentPosition: true,
                itemSelector: '.grid-item',
            });

            setTimeout(function() {
                msnry.layout();
            }, 3000);
            setTimeout(function() {
                msnry.layout();
            }, 6000);
            setTimeout(function() {
                msnry.layout();
            }, 9000);
        }, 250);
    }
});
