$(() => {
    const mySwiper = new Swiper('.swiper-container', {
        // Optional parameters
        preloadImages: false,
        lazy: false,
        direction: 'horizontal',
        observer: true,
        loop: true,
        autoHeight: true,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
});

