$(() => {
    // ADD RECAPTACH TO HTML HEAD <script src='https://www.google.com/recaptcha/api.js?render=6LdPkcUUAAAAAAO7nh8fGun0ZHAy3tdAtvC4GBpE'></script>
    if (document.getElementsByClassName('contact').length) {
        document.querySelector('#contact').addEventListener('submit', function(e) {
            e.preventDefault();
            const form = $(this);
            const loading = form.find('.formloading');
            loading.removeClass('hidden');
            const success = form.find('.success');
            const error = form.find('.error');
            const url = form.attr('action');

            grecaptcha.execute('6LdPkcUUAAAAAAO7nh8fGun0ZHAy3tdAtvC4GBpE', {action: 'Contact_Form'}).then(function(token) {
                const params = form.serialize() + '&recaptcha=' + token;
                const xhr = new XMLHttpRequest();
                xhr.open('POST', url);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                // Setup our listener to process completed requests
                xhr.onload = function() {
                    // Process our return data
                    if (xhr.status >= 200 && xhr.status < 300) {
                        // What do when the request is successful
                        const response = JSON.parse(this.response);
                        if (response.success) {
                            error.html('');
                            success.html(response.message);
                        }
                        if (response.error) {
                            let message = '';
                            for (const key in response.error) {
                                if (response.error.hasOwnProperty(key)) {
                                    message = message + response.error[key] + '\n';
                                }
                            }
                            error.html(message);
                        }
                        loading.addClass('hidden');
                    }
                };

                xhr.send(params);
            });
        });

        const inputs = $('.contact').find('input:not([type="checkbox"]):not([type="radio"])');
        const textareas = $('.contact').find('textarea');
        inputs.on('input', function() {
            if ($(this).val() === '') {
                $(this).removeClass('not-empty');
            } else {
                $(this).addClass('not-empty');
            }
        });

        textareas.on('input', function() {
            if ($(this).val() === '') {
                $(this).removeClass('not-empty');
            } else {
                $(this).addClass('not-empty');
            }
        });
    }
});
