/*
    HTML for slideToggle
    <div class="dropdown-link"></div>
    <div class="opener"></div>
 */

const slideToggle = ($link) => {
    const $container = $link.next();
    $container.off();

    if (!$container.hasClass('open')) {
        $container.addClass('open');
        $link.addClass('isOpen');
        $container.css('height', 'auto');

        const height = $container.height();

        $container.height(0);

        setTimeout(function() {
            $container.height(height);
            $container.on('transitionend webkitTransitionEnd oTransitionEnd', function() {
                $container.css('height', 'auto');
                $container.off();
            });
        }, 0);
    } else {
        const height = $container.height();
        $container.height(height);
        setTimeout(function() {
            $container.height(0);

            $link.removeClass('isOpen');
            $container.on('transitionend webkitTransitionEnd oTransitionEnd', function() {
                $container.removeClass('open');
                $container.off();
            });
        });
    }
};


