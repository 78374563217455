document.addEventListener("DOMContentLoaded", function () {
  const form = document.getElementById("contact");
  const successMessage = document.querySelector(".success");
  const errorMessage = document.querySelector(".error");
  const loadingSpinner = document.querySelector(".formloading");

  form.addEventListener("submit", function (e) {
    e.preventDefault();

    // Check if honeypot field is filled out
    const honeypot = form.querySelector('input[name="betreff"]');
    if (honeypot && honeypot.value) {
      // If the honeypot field has been filled out, it's likely a bot
      errorMessage.innerHTML =
        "Fehler beim Senden der Nachricht. Bitte versuchen Sie es später erneut.";
      errorMessage.style.display = "block";
      successMessage.style.display = "none";
      return; // Prevent the form from being submitted
    }

    // Show loading spinner
    loadingSpinner.classList.remove("hidden");

    const formData = new FormData(form);

    // Create and configure the AJAX request
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "php/contact.php", true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        loadingSpinner.classList.add("hidden");
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);

          if (response.status === "success") {
            successMessage.innerHTML = response.message;
            successMessage.style.display = "block";
            errorMessage.style.display = "none";
            form.reset();
          } else {
            errorMessage.innerHTML = response.message;
            errorMessage.style.display = "block";
            successMessage.style.display = "none";
          }
        } else {
          errorMessage.innerHTML =
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
          errorMessage.style.display = "block";
          successMessage.style.display = "none";
        }
      }
    };

    // Send the form data
    xhr.send(formData);
  });
});
