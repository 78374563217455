function getCookieForCookieLaw(name) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

function cookieLawClick() {
    document.getElementById('cookielawbutton').style.color = '#000000';
    document.getElementById('cookielaw').style.transform = 'translateY(100%)';
    document.cookie = 'law_cookie_accepted=yes; path=/; expires=Thu, 31 Dec 2099 23:59:59 UTC;';
}

function cookieEnable() {
    if (getCookieForCookieLaw('law_cookie_accepted') !== 'yes') {
        const lawhtml = '<div id="cookielaw"><div><p>Cookies ermöglichen eine bestmögliche Bereitstellung unserer Dienste. Mit der Nutzung dieser Seiten und Services erklären Sie sich damit einverstanden, dass wir Cookies verwenden.</p><a id="cookielawlink" href="/datenschutz.html">Mehr Infos</a></div><a class="button notransition" id="cookielawbutton" href="javascript:void(0);"><span></span><span></span>OK</a></div>';
        const bodyElement = document.body;
        bodyElement.insertAdjacentHTML( 'afterbegin', lawhtml );
        document.getElementById('cookielawbutton').addEventListener('click', cookieLawClick, false);

        window.onload = function() {
            document.getElementById('cookielaw').style.transform = 'translateY(0%)';
        };
    }
}

function showCookieFail() {
    console.log('Cookies deaktiviert');
}

function checkCookie() {
    let cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }

    if (cookieEnabled) {
        cookieEnable();
    } else {
        showCookieFail();
    }
}

checkCookie();


// Twitter START
const disableStrTwitter = 'twitter-disable';
if (document.cookie.indexOf(disableStrTwitter + '=true') > -1) { window[disableStrTwitter] = true;}
function twitterOptout() {
    document.cookie = disableStrTwitter + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[disableStrTwitter] = true;
}
// Facebook END


// Facebook START
const disableStrFB = 'fb-disable';
if (document.cookie.indexOf(disableStrFB + '=true') > -1) { window[disableStrFB] = true;}
function fbOptout() {
    document.cookie = disableStrFB + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[disableStrFB] = true;
}
// Facebook END


// Google Analytics START
const gaProperty = 'UA-1364477-10';
const disableStrGA = 'ga-disable-' + gaProperty;

if (document.cookie.indexOf(disableStrGA + '=true') > -1) { window[disableStrGA] = true;}
function gaOptout() {
    document.cookie = disableStrGA + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[disableStrGA] = true;
    alert('Das Tracking durch Google Analytics wurde in Ihrem Browser für diese Website deaktiviert.');
}

const imported = document.createElement('script');
imported.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaProperty;
document.head.appendChild(imported);


window.dataLayer = window.dataLayer || [];
function gtag() {dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', gaProperty);
// Google Analytics END


// Youtube START
const disableStrYT = 'yt-disable';
if (document.cookie.indexOf(disableStrYT + '=true') > -1) {
    window[disableStrYT] = true;
    Array.from(document.getElementsByClassName('swiper-yt')).forEach(
        function(element, index, array) {
            const sliderelement = element.parentNode;
            const wrapper = sliderelement.parentNode;
            wrapper.removeChild(sliderelement);
        }
    );
    Array.from(document.getElementsByClassName('youtube')).forEach(
        function(element, index, array) {
            const parentelement = element.parentNode;
            parentelement.removeChild(element);
        }
    );
}
function ytOptout() {
    document.cookie = disableStrYT + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[disableStrYT] = true;
}
// Youtube END
